/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const getSchemaEventOrgJSONLD = ({
  isBlogPost,
  url,
  title,
  image,
  description,
  datePublished,
  siteTitle,
  siteUrl,
  shortName,
  event
}) => (
    {
      "@context": "http://schema.org",
      "@type": "SportsEvent",
      "name": event.name,
      "description": title,
      "startDate": datePublished,
      "endDate": datePublished,
      "competitor": [
        {
          "@type": "SportsTeam",
          "name": event.team1,
        },
        {
          "@type": "SportsTeam",
          "name": event.team2,
        }
      ],
      "location": {
        "@type": "Place",
        "address": event.place
      }
    });

const getSchemaOrgJSONLD = ({
  isBlogPost,
  url,
  title,
  image,
  description,
  datePublished,
  siteTitle,
  siteUrl,
  shortName,
  authorName,
  authorUrl,
  authorSocial
}) => {
  // console.log(authorName);
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url,
      name: title,
      alternateName: siteTitle,
    },
  ];
  return isBlogPost
    ?
    {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "headline": title,
      "image": [
        image,
      ],
      "datePublished": datePublished,
      "dateModified": datePublished,
      "publisher": {
        "@type": "Organization",
        "name": shortName,
        "url": siteUrl, 
        "logo": {
          "@type": "ImageObject",
          "url": "https://www.thaimarket.net/static/thaimarket-582f2ac4dcf69efe467292a97b899151.png",
          "width": 125,
          "height": 30,
            },
        
        "sameAs" : [
          "https://www.pinterest.com/thaimarket",
          "https://www.twitter.com/thaimarket",
                    
          ]
        },
      "author": {
        "@type": "Person",
        "name": authorName,
        "url" : authorUrl,
        "sameAs": authorSocial
      },
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": url,
      },
      "description": description
    }
    : schemaOrgJSONLD;
};



function SEO({ canonical, description, lang, meta, title, image, isBlogPost, datePublished, event, authorName, authorUrl, authorSocial }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            shortName
          }
        }
      }
    `
  )
  const siteTitle = site.siteMetadata.title;
  const siteUrl = site.siteMetadata.sitesiteUrl;
  const shortName = site.siteMetadata.shortName;
  // console.log('cconasofncaosncadc', event);
  const schemaOrgJSONLD = event !== undefined ? getSchemaEventOrgJSONLD({
    isBlogPost,
    canonical,
    title,
    image,
    description,
    datePublished,
    siteTitle,
    siteUrl,
    shortName,
    event
  })
    : getSchemaOrgJSONLD({
      isBlogPost,
      canonical,
      title,
      image,
      description,
      datePublished,
      siteTitle,
      siteUrl,
      shortName,
      authorName,
      authorUrl,
      authorSocial
    });

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      link={
        canonical
          ? [{ rel: 'canonical', key: canonical, href: canonical }]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `image`,
          content: image ? image : '',
        },
        {
          name: `og:url`,
          content: canonical,
        },
        {
          property: `og:type`,
          content: isBlogPost ? 'article' : 'website',
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image ? image : '',
        },
        {
          property: `og:type`,
          content: `article`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: image ? image : '',
        },
        {
          name: `p:domain_verify`,
          content: "",
        },
        {
          name: `google-site-verification`, 
          content: "",
        },
      ].concat(meta)}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: `${JSON.stringify(schemaOrgJSONLD)}`
        }
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `vi`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  canonical: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
