import React from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Button } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Sidebar from "../components/Sidebar"
import { SlugLink } from '../components/utils'
import moment from "moment";
const baseuri = "https://dmf5.xyz"
class BlogPostTemplate extends React.Component {

  render() {
    const post = this.props.data.privateGraphql.getnew
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteUrl = this.props.data.site.siteMetadata.siteUrl
    const Author = this.props.data.privateGraphql.getauthorbynewslugtitle;
    const { previous, next } = this.props.pageContext
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.title}
          description={post.short}
          image={`${post.image}`}
          isBlogPost={true}
          datePublished={post.publishDate}
          authorName={post.profileName}
          authorUrl={`${siteUrl}/authors/${post.profileSlugName}`}
          authorSocial={Author.twitter}
        />
        <div className="row">
          <article className="single-post col-lg-8 col-md-12 m-auto">
            <header className="single-content">
              <div className="info-meta">
                <div className="post-info__terms-list">
                  {post.tags !== null ?
                    post.tags.split(',').map(tagx => {
                      if (tagx !== "") return (
                        <Link to={`/${SlugLink(tagx)}`} className="tags-terms-list-item" key={tagx}>{tagx}</Link>
                      )
                    })
                    :
                    null
                  }
                </div>
                <h1 className="elementor-heading-title">{post.title}</h1>
                <div className="author-info">
                  <div className="ele-sp detail-author">
                    <Link to={`/authors/${Author.slugname}`} className="author-box__avatar" title={Author.profilename}>
                      <LazyLoadImage
                        title={Author.profileName}
                        src={Author.image}
                        effect="blur"
                      />
                      <div className="author-box__text">{Author.profilename}</div>
                    </Link>
                  </div>
                  <div className="ele-sp author-box__time"> {moment(post.publishDate).format('MMM Do YYYY')}</div>
                </div>



              </div>
            </header>
            <div className="element-post-entry">
              <div dangerouslySetInnerHTML={{ __html: post.detail }} />
            </div>
          </article>
          <Sidebar></Sidebar>
        </div>
        <div className="row justify-content-center block-relate">
          <div className="col-lg-10 col-md-12 col-xs-12 text-center item-relate-h">
          <div className="elementor-widget-container">
			      <div className="elementor-heading-title elementor-size-default">Recommended Articles </div>		</div>
          </div>
          <Col xs={12} md={6} lg={4} className="item-relate">
            {previous && (
              <article className="related-post-henry">
                <Link to={previous.slugtitle} title={previous.title} className="post-image post-image-related">
                  <div className="featured-thumbnail">
                    <img src={previous.image} />
                  </div>
                </Link>
                    <h3 className="post-title">
                      <Link to={previous.slugtitle} title={previous.title}>{previous.title}</Link>
                    </h3>
              </article>
            )}

          </Col>
          <Col xs={12} md={6} lg={4} className="item-relate">
            {next && (
              <article className="related-post-henry">
                <Link to={next.slugtitle} title={next.title} className="post-image post-image-related">
                  <div className="featured-thumbnail">
                    <img src={next.image} />
                  </div>
                </Link>
                    <h3 className="post-title">
                      <Link to={next.slugtitle} title={next.title}>{next.title}</Link>
                    </h3>
              </article>
            )}
          </Col>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query ($slug: String = "404",$siteId: Int = 1082) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    privateGraphql{
      getauthorbynewslugtitle(slugtitle: $slug, siteId: $siteId) {
        id
        profilename
        twitter
        slugname
        image
        bio
      }
      getnew(slugtitle: $slug, siteId: $siteId) {
        id
        detail
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
      }
    }
  }
`
